import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import ImageSwiperEasyTalk from "../components/ImageSwiperEasyTalk";
import ImageSwiperEasyTalkSketches from "../components/ImageSwiperEasyTalkSketches"
import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">user design</Text>
                <Title variant="secSm" className="my-4">
                  Easy Talk{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Easy Talk is a mobile application that aims to assist people suffering from autism, as well as those with issues 
                  communicating due to limited speech. It accomplishes this by using a method called alternative and augmentative 
                  communication (AAC) for communication. 
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Roles</Text>
                <Title variant="cardBig" className="mt-3">
                  UX & UI Designer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Sep 2017 - Dec 2017
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://projects.invisionapp.com/share/8411DUB1HAUT#/455491013_Loading_Screen" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>App Prototype</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: 60}}>
              Project Process
            </Title>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  01. Problem Statement
                </Title>
                <Text variant="p" className="mb-4">
                  In the United States, 1 in 68 children struggle with autism spectrum disorder that can cause them difficulties performing
                  tasks on their own. We decided to step into this problem space and design an app that would help people suffering from autism,
                  as well as those with issues communicating due to limited speech. We aim to assist users in communicating independently and
                  give the ability to plan out their own day.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  02. Solution Proposal
                </Title>
                <Text variant="p" className="mb-4">
                  To intervene in the problem space of assisting those with autism of speech difficulties, we plan to design a mobile application
                  that includes a method for communication through
                  <a href="https://www.assistiveware.com/learn-aac/what-is-aac" target="_blank"> alternative and augmentative communication (AAC) </a>
                  and a visual schedule.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  03. User Research
                </Title>
                <Text variant="p" className="mb-4">
                  We conducted an interview with a person that has autism and we learned how common it was for them to face communication barriers
                  in their everyday life. Having a picture to speech app can help them better communicate with others who don't understand their
                  disability. They would benefit from an app that is simple and straight to the point. They do not want too many complex pictures and options,
                  because that will create confusion and ruin the whole experience of the mobile application.
                </Text>
                <Text variant="p" className="mb-4">
                  We created two user personas to help provide us a reliable and realistic representation of our key audiences:
                </Text>
                <ImageSwiperEasyTalk></ImageSwiperEasyTalk>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
                  04. Low-fidelity Sketches
                </Title>
                <ImageSwiperEasyTalkSketches></ImageSwiperEasyTalkSketches>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
                  05. High-fidelity Prototype
                </Title>
                <Text variant="p" className="mb-4">
                  To create our high-fidelity prototype, our team used
                  < a href="https://www.figma.com/"> Figma </a> to create the necessary UI components and application screens. We exported our 
                  finalized screens created in Figma to
                  <a href="https://www.invisionapp.com/" target="_blank"> InVision </a> where we were able to create an interactive prototype
                  of our mobile application:
                </Text>
                <iframe width="438" height="930" src="//projects.invisionapp.com/share/AN11DUB3F8TW" frameborder="0" allowfullscreen></iframe>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  06. Outcomes
                </Title>
                <Text variant="p" className="mb-4">
                  This project was my first experience using UX & UI design to create a mobile application prototype from scratch. It was exciting
                  being able to create something that others could interact with. I have gained an understanding about the importance of knowing 
                  your target audience through conducting user research. Once the project was completed, our team presented our mobile application
                  prototype in a project fair where attendees also interacted with our product. This project marked the beginning of my journey as
                  a UX designer.
                </Text>
          </Container>
        </Section>
        {/* <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/easy-talk">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Easy Talk
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
