import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from './core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';

const ImageSwiperEasyTalkSketches = () => {
  return (
    <AwesomeSlider animation="scaleOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1620144583/Easy%20Talk/Sketch_1.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1620144583/Easy%20Talk/Sketch_2.png"/>
    </AwesomeSlider>
  );
};

export default ImageSwiperEasyTalkSketches;